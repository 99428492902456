* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

body {
  color: #ccc;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

.instruction {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

@media screen and (max-width: 700px) {
  .instruction {
    display: none;
  }
}

.credits {
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: right;
}

.credits a {
  color: inherit;
}

.instruction,
.credits {
  font-size: 14px;
  line-height: 1.4;
}
